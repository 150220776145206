var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "main" },
    [
      _c("p", { staticClass: "header" }, [_vm._v(" 单据信息 ")]),
      _c("table", { staticClass: "detail-form" }, [
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v("状态：" + _vm._s(_setup.tradeOrderVo.refundStateName)),
            ]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v("订货退货单号：" + _vm._s(_setup.tradeOrderVo.refundNo)),
            ]),
          ]),
          _c("td", [
            _vm._v("关联订货单：" + _vm._s(_setup.tradeOrderVo.supplyOrderNo)),
          ]),
          _c("td", [
            _vm._v(
              "创建时间：" +
                _vm._s(
                  _setup
                    .dayjs(_setup.tradeOrderVo.createTime)
                    .format("YYYY-MM-DD HH:mm:ss")
                )
            ),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "header" }, [_vm._v(" 申请信息 ")]),
      _c("table", { staticClass: "detail-form" }, [
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v("类型：" + _vm._s(_setup.tradeOrderVo.refundTypeName)),
            ]),
          ]),
          _c("td", [
            _vm._v(
              "物流公司：" + _vm._s(_setup.tradeOrderVo.logisticName || "-")
            ),
          ]),
          _c("td", [
            _vm._v("运单号：" + _vm._s(_setup.tradeOrderVo.logisticNo || "-")),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [_vm._v("原因：" + _vm._s(_setup.tradeOrderVo.reason))]),
          ]),
        ]),
        _c("tr", [
          _c("td", { staticClass: "countdownWrap" }, [
            _c("span", [
              _vm._v("补充描述：" + _vm._s(_setup.tradeOrderVo.reasonDesc)),
            ]),
          ]),
        ]),
      ]),
      _c("p", { staticClass: "header" }, [_vm._v(" 商品信息 ")]),
      _c(
        "el-table",
        {
          attrs: {
            data: _setup.goodsData,
            border: "",
            "header-row-style": { "font-weight": 100, color: "#0D0202" },
            "row-style": { "font-size": "14px", color: "#0D0202" },
            "header-cell-style": {
              "font-weight": "bold",
              background: "#F0F2F8",
            },
          },
          on: { "selection-change": _setup.handleSelectionChange },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "goodsId", label: "商品ID" },
          }),
          _c("el-table-column", {
            attrs: { prop: "goodsName", label: "商品名称" },
          }),
          _c("el-table-column", {
            attrs: { prop: "skuName", label: "商品规格" },
          }),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: {
                  prop: "userPayment",
                  label: "用户实付金额（元）",
                  width: "160",
                },
              })
            : _vm._e(),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: { prop: "userRefundCount", label: "用户退货数" },
              })
            : _vm._e(),
          _setup.isMemberStore
            ? _c("el-table-column", {
                attrs: {
                  prop: "userRefundAmount",
                  width: "160",
                  label: "用户退款金额（元）",
                },
              })
            : _vm._e(),
          _c("el-table-column", {
            attrs: { prop: "purchaseCount", label: "订货数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "purchaseAmount",
              label: "订货商品金额（元）",
              width: "160",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "refundCount", label: "订货退货数" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "refundAmount",
              label: "订货退货金额（元）",
              width: "160",
            },
          }),
        ],
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "back",
          on: {
            click: function ($event) {
              return _setup.$router.go(-1)
            },
          },
        },
        [_vm._v(" 返 回 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }