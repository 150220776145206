.
<template>
  <div class="main">
    <p class="header">
      单据信息
    </p>
    <table class="detail-form">
      <tr>
        <td class="countdownWrap">
          <span>状态：{{ tradeOrderVo.refundStateName }}</span>
        </td>
      </tr>
      <tr>
        <td class="countdownWrap">
          <span>订货退货单号：{{ tradeOrderVo.refundNo }}</span>
        </td>
        <td>关联订货单：{{ tradeOrderVo.supplyOrderNo }}</td>
        <td>创建时间：{{ dayjs(tradeOrderVo.createTime).format('YYYY-MM-DD HH:mm:ss') }}</td>
      </tr>
    </table>
    <p class="header">
      申请信息
    </p>
    <table class="detail-form">
      <tr>
        <td class="countdownWrap">
          <span>类型：{{ tradeOrderVo.refundTypeName }}</span>
        </td>
        <td>物流公司：{{ tradeOrderVo.logisticName || '-' }}</td>
        <td>运单号：{{ tradeOrderVo.logisticNo || '-' }}</td>
      </tr>
      <tr>
        <td class="countdownWrap">
          <span>原因：{{ tradeOrderVo.reason }}</span>
        </td>
      </tr>
      <tr>
        <td class="countdownWrap">
          <span>补充描述：{{ tradeOrderVo.reasonDesc }}</span>
        </td>
      </tr>
    </table>
    <p class="header">
      商品信息
    </p>
    <el-table
      :data="goodsData"
      border
      :header-row-style="{'font-weight': 100, 'color': '#0D0202'}"
      :row-style="{'font-size': '14px', 'color': '#0D0202'}"
      :header-cell-style="{'font-weight': 'bold', 'background': '#F0F2F8'}"
      @selection-change="handleSelectionChange"
    >
      <el-table-column
        prop="goodsId"
        label="商品ID"
      />
      <el-table-column
        prop="goodsName"
        label="商品名称"
      />
      <el-table-column
        prop="skuName"
        label="商品规格"
      />
      <el-table-column
        v-if="isMemberStore"
        prop="userPayment"
        label="用户实付金额（元）"
        width="160"
      />
      <el-table-column
        v-if="isMemberStore"
        prop="userRefundCount"
        label="用户退货数"
      />
      <el-table-column
        v-if="isMemberStore"
        prop="userRefundAmount"
        width="160"
        label="用户退款金额（元）"
      />
      <el-table-column
        prop="purchaseCount"
        label="订货数"
      />
      <el-table-column
        prop="purchaseAmount"
        label="订货商品金额（元）"
        width="160"
      />
      <el-table-column
        prop="refundCount"
        label="订货退货数"
      />
      <el-table-column
        prop="refundAmount"
        label="订货退货金额（元）"
        width="160"
      />
    </el-table>
    <el-button
      class="back"
      @click="$router.go(-1)"
    >
      返 回
    </el-button>
  </div>
</template>

<script setup>
import {
  ref, onMounted, getCurrentInstance, computed,
} from 'vue';
import dayjs from 'dayjs';

const app = getCurrentInstance().proxy;
const { $route, $router } = app;
const tradeOrderVo = ref({}); // 订单信息
const goodsData = ref([]); // 商品信息
const subOrderIdList = ref([]); // 选中的子订单id
const orderDetail = ref({}); // 订单详情
// const trackData = ref([]); // 物流信息
// 计算属性简写方式(计算属性不可被修改)
const { isMemberStore } = JSON.parse(localStorage.getItem('userInfo'));
const orderId = computed(() => {
  return $route.query.orderId;
});

const handleSelectionChange = (val) => {
  subOrderIdList.value = val.map((item) => item.subOrderId);
};

const getDetail = async () => {
  const res = await app.$axios.get(app.$api.supplierOrder.detail, {
    params: {
      id: orderId.value,
    },
  });
  orderDetail.value = res.data;
  tradeOrderVo.value = res.data;
  goodsData.value = [
    {
      goodsId: res.data.supplyOrderGoodsId, // 商品ID
      goodsName: res.data.supplyGoodsName, // 商品名称
      skuName: res.data.supplySkuName, // 商品规格
      purchaseCount: res.data.purchaseCount, // 订货数
      purchaseAmount: res.data.purchaseAmount, // 订货商品金额
      refundCount: res.data.refundCount, // 订货退货数
      refundAmount: res.data.refundAmount, // 订货退货金额
      userPayment: res.data.userPayment, // 用户实付金额
      userRefundCount: res.data.userRefundCount, // 用户退货数
      userRefundAmount: res.data.userRefundAmount, // 用户退款金额
    },
  ];
};

onMounted(() => {
  getDetail();
});
</script>

<style lang="scss" scoped>
.main {
  background: #fff;
  padding: 20px;
  .back {
    margin-top: 20px;
  }
}
.header {
  border-left: 3px solid #409EFF;
  padding: 12px;
  font-size: 18px;
  margin: 20px 0;
  .tip {
    font-size: 12px;
    color: #999
  }
  .title {
    display: inline-block;
    margin-right: 50px;
  }
}
.detail-form {
  margin-top: 10px;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
  td {
    padding: 12px 16px;
  }
}
.countdownWrap{
  position:relative;
}
.countdown{
  color:red;
  position:absolute;
  top:12px;
  white-space: nowrap;
}
.order-tips {
  font-size: 14px;
  border: 1px solid #ebeef5;
  padding: 20px;
  text-align: center;
}
.goods{
  display: flex;
  img {
    width: 70px;
    height: 70px;
  }
  p {
    padding: 0 12px;
  }
  .id {
    color: #736E6E;
  }
}
.remark {
  padding: 12px 8px;
  border: 1px solid #dfe6ec;
  margin-bottom: 10px;
}
</style>
